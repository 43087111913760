.additional {
  position: relative;
  display: inline-block;
  color: rgba(#fff, .5);
  line-height: 15px;
  border-bottom: 1px dotted #fff;
  :global .embedded-progress :local & {
    color: rgba(#000, .25);
    border-bottom-color: rgba(#000, .5);
  }
  &._dark {
    color: rgba(#000, .25);
    border-bottom-color: rgba(#000, .5);
  }
}

.tip {
  position: absolute;
  top: -30px;
  left: 50%;
  display: none;
  margin-left: -131px;
  padding: 2px 12px;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background-color: rgba(#000, .75);
  border-radius: 5px;
  .additional:hover & {
    display: block;
  }
  &:before {
    content: '';
    position: absolute;
    top: 24px;
    right: 13px;
    width: 12px;
    height: 6px;
    background: url(./images/arr.svg) center no-repeat;
    background-size: 12px 6px;
    transform: rotate(180deg);
  }
}