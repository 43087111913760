.wrapper {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
}

.tipTitle {
  font-family: Montserrat-Regular;
  font-size: 9px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 15px;
}

.tip {
  box-sizing: border-box;
  position: absolute;
  bottom: 100%;
  left: 50%;
  display: none;
  padding: 2px 12px;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  background-color: rgba(#000, .75);
  border-radius: 5px;
  transform: translateX(-50%) translateY(-11px);
  z-index: 10;

  .wrapper:hover & {
    display: block;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 50%;
    width: 12px;
    height: 6px;
    margin-left: -6px;
    background: url(./images/arr.svg) center no-repeat;
    background-size: 12px 6px;
    transform: rotate(180deg);
  }

  &._under {
    top: 100%;
    bottom: auto;
    transform: translateX(-50%) translateY(11px);

    &:before {
      top: -6px;
      bottom: auto;
      transform: rotate(0);
    }
  }
}