.date {
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 18px;
}

.text {
  font-size: 12px;
  line-height: 15px;
  color: #888;
}
