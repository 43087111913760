.progress {
  position: relative;
  padding: 20px 0;
  color: rgba(#fff, .75);
  font-size: 12px;
  line-height: 15px;
  :global .embedded-progress :local & {
    padding-top: 0;
    color: rgba(#000, .5);
  }
  &._light {
    padding-top: 5px;
    color: rgba(#000, .5);
  }
  &._full:before {
    content: '';
    position: absolute;
    right: -15px;
    bottom: 0;
    left: -15px;
    height: 1px;
    background-color: #2d5d7d;
  }
}

.runners {
  margin-bottom: 11px;
}

.runner {
  position: relative;
  width: 100%;
  height: 30px;
  margin-bottom: 2px;
  background-color: #134163;
  :global .embedded-progress :local &,
  .progress._light & {
    background-color: #f3f3f3;
  }
  &._small {
    height: 2px;
  }
}

.runner__inner {
  position: absolute;
  top: 0;
  right: -1px;
  left: 0;
  height: inherit;
  white-space: nowrap;
}

.item {
  box-sizing: border-box;
  display: inline-block;
  min-width: 2px;
  height: inherit;
  padding: 0 1px 0 0;
}

.color {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: inherit;
  transition: background-color .2s;
  .item._purple & {
    background-color: #d4d4ff;
    &:hover {
      background-color: darken(#d4d4ff, 10%);
    }
  }
  .item._blue & {
    background-color: #99DFFF;
    &:hover {
      background-color: darken(#99DFFF, 10%);
    }
  }
  .item._green & {
    background-color: #BAE3A3;
    &:hover {
      background-color: darken(#BAE3A3, 10%);
    }
  }
  .item._yellow & {
    background-color: #E3DF96;
    &:hover {
      background-color: darken(#E3DF96, 10%);
    }
  }
  .item._pink & {
    background-color: #FFD7C4;
    &:hover {
      background-color: darken(#FFD7C4, 10%);
    }
  }
}

.item._empty {
  display: table-cell;
}

.individuals {
  margin-bottom: 10px
}

.individual {
  display: table;
  width: 100%;
  height: 20px;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 20px;
}

.individual__title {
  display: table-cell;
  width: 75px;
}

.individual__progress {
  position: relative;
  display: table-cell;
  width: 200px;
}

.individual__common {
  position: absolute;
  top: 5px;
  left: 0;
  width: 100%;
  height: 10px;
}

.individual__additional {
  position: absolute;
  top: 17px;
  left: 0;
  width: 100%;
  height: 2px;
}

.individual__common,
.individual__additional {
  .individual._purple & {
  background-color: rgba(#d4d4ff, .5);
  }
  .individual._blue & {
    background-color: rgba(#99DFFF, .5);
  }
  .individual._green & {
    background-color: rgba(#BAE3A3, .5);
  }
  .individual._yellow & {
    background-color: rgba(#E3DF96, .5);
  }
  .individual._pink & {
    background-color: rgba(#FFD7C4, .5);
  }
  span {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: inherit;
    .individual._purple & {
      background-color: #d4d4ff;
    }
    .individual._blue & {
      background-color: #99DFFF;
    }
    .individual._green & {
      background-color: #BAE3A3;
    }
    .individual._yellow & {
      background-color: #E3DF96;
    }
    .individual._pink & {
      background-color: #FFD7C4;
    }
  }
}

.individual__text {
  display: table-cell;
  padding-left: 10px;
}