@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville|Montserrat:200,300,400,500,600,700');

@tailwind base;

@layer base {
  html { @apply scroll-smooth }
  body {
    @apply text-sm text-black-drh antialiased;
  }

  :root {
    --toastify-font-family: 'Montserrat';
    --toastify-toast-width: auto;
    --toastify-toast-min-height: auto;
    --toastify-color-info: theme('colors.primary.DEFAULT');
    --toastify-color-success: theme('colors.success.DEFAULT');
    --toastify-color-warning: theme('colors.warning.DEFAULT');
    --toastify-color-error: theme('colors.danger.DEFAULT');
    --toastify-color-transparent: rgb(0,0,0,0.1);
  }
}

@tailwind components;
@tailwind utilities;

@layer components {
  .link {
    @apply text-primary underline decoration-transparent hover:decoration-primary transition-colors
  }
  .input-number-no-arrows {
    @apply [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none
  }
}
